var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/* !
 * @author Sean Coker <hello@sean.is>
 * @version 1.15.0
 * @url http://sean.is/poppin/tags
 * @license MIT
 * @description Taggle is a dependency-less tagging library
 */
// @todo remove bower from next major version
(function (root, factory) {
  'use strict';

  var libName = "Taggle";
  /* global define, module */

  if (exports) {
    exports = root[libName] = factory();
  } else {
    root[libName] = factory();
  }
})(exports, function () {
  'use strict'; /////////////////////
  // Default options //
  /////////////////////

  var noop = function () {};

  var retTrue = function () {
    return true;
  };

  var BACKSPACE = 8;
  var DELETE = 46;
  var COMMA = 188;
  var TAB = 9;
  var ENTER = 13;
  var ARROW_LEFT = 37;
  var ARROW_RIGHT = 39;
  var DEFAULTS = {
    /**
     * Class names to be added on each tag entered
     * @type {String}
     */
    additionalTagClasses: "",

    /**
     * Allow duplicate tags to be entered in the field?
     * @type {Boolean}
     */
    allowDuplicates: false,

    /**
     * Allow the saving of a tag on blur, rather than it being
     * removed.
     *
     * @type {Boolean}
     */
    saveOnBlur: false,

    /**
     * Clear the input value when blurring.
     *
     * @type {Boolean}
     */
    clearOnBlur: true,

    /**
     * Class name that will be added onto duplicate existant tag
     * @type {String}
     * @todo
     * @deprecated can be handled by onBeforeTagAdd
     */
    duplicateTagClass: "",

    /**
     * Class added to the container div when focused
     * @type {String}
     */
    containerFocusClass: "active",

    /**
     * Should the input be focused when the container is clicked?
     * @type {Bool}
     */
    focusInputOnContainerClick: true,

    /**
     * Name added to the hidden inputs within each tag
     * @type {String}
     */
    hiddenInputName: "taggles[]",

    /**
     * Tags that should be preloaded in the div on load
     * @type {Array}
     */
    tags: [],

    /**
     * The default delimeter character to split tags on
     * @type {String}
     * @todo Change this to just "delimiter: ','"
     */
    delimeter: ",",
    delimiter: "",

    /**
     * Add an ID to each of the tags.
     * @type {Boolean}
     * @todo
     * @deprecated make this the default in next version
     */
    attachTagId: false,

    /**
     * Tags that the user will be restricted to
     * @type {Array}
     */
    allowedTags: [],

    /**
     * Tags that the user will not be able to add
     * @type {Array}
     */
    disallowedTags: [],

    /**
     * Spaces will be removed from the tags by default
     * @type {Boolean}
     */
    trimTags: true,

    /**
     * Limit the number of tags that can be added
     * @type {Number}
     */
    maxTags: null,

    /**
     * If within a form, you can specify the tab index flow
     * @type {Number}
     * @todo  make 0 in next update
     */
    tabIndex: 1,

    /**
     * Placeholder string to be placed in an empty taggle field
     * @type {String}
     */
    placeholder: "Enter tags...",

    /**
     * Keycodes that will add a tag
     * @type {Array}
     */
    submitKeys: [COMMA, TAB, ENTER],

    /**
     * Preserve case of tags being added ie
     * "tag" is different than "Tag"
     * @type {Boolean}
     */
    preserveCase: false,
    // @todo bind callback hooks to instance

    /**
     * Function hook called with the to-be-added input DOM element.
     *
     * @param  {HTMLElement} input The input element to be added
     */
    inputFormatter: noop,

    /**
     * Function hook called with the to-be-added tag DOM element.
     * Use this function to edit the list item before it is appended
     * to the DOM
     * @param  {HTMLElement} li The list item to be added
     */
    tagFormatter: noop,

    /**
     * Function hook called before a tag is added. Return false
     * to prevent tag from being added
     * @param  {String} tag The tag to be added
     */
    onBeforeTagAdd: noop,

    /**
     * Function hook called when a tag is added
     * @param  {Event} event Event triggered when tag was added
     * @param  {String} tag The tag added
     */
    onTagAdd: noop,

    /**
     * Function hook called before a tag is removed. Return false
     * to prevent tag from being removed
     * @param  {String} tag The tag to be removed
     */
    onBeforeTagRemove: retTrue,

    /**
     * Function hook called when a tag is removed
     * @param  {Event} event Event triggered when tag was removed
     * @param  {String} tag The tag removed
     */
    onTagRemove: noop
  }; //////////////////////
  // Helper functions //
  //////////////////////

  function _extend() {
    var master = arguments[0];

    for (var i = 1, l = arguments.length; i < l; i++) {
      var object = arguments[i];

      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          master[key] = object[key];
        }
      }
    }

    return master;
  }

  function _isArray(arr) {
    if (Array.isArray) {
      return Array.isArray(arr);
    }

    return Object.prototype.toString.call(arr) === "[object Array]";
  }

  function _on(element, eventName, handler) {
    if (element.addEventListener) {
      element.addEventListener(eventName, handler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + eventName, handler);
    } else {
      element["on" + eventName] = handler;
    }
  }

  function _off(element, eventName, handler) {
    if (element.removeEventListener) {
      element.removeEventListener(eventName, handler, false);
    } else if (element.detachEvent) {
      element.detachEvent("on" + eventName, handler);
    } else {
      element["on" + eventName] = null;
    }
  }

  function _trim(str) {
    return str.replace(/^\s+|\s+$/g, "");
  }

  function _setText(el, text) {
    if (window.attachEvent && !window.addEventListener) {
      // <= IE8
      el.innerText = text;
    } else {
      el.textContent = text;
    }
  }

  function _clamp(val, min, max) {
    return Math.min(Math.max(val, min), max);
  }
  /**
   * Constructor
   * @param {Mixed} el ID of an element or the actual element
   * @param {Object} options
   */


  var Taggle = function (el, options) {
    // @todo also check that option type is correct #106
    // @todo uncomment this in next major version
    // for (var key in (options || {})) {
    //     if (!DEFAULTS.hasOwnProperty(key)) {
    //         throw new Error('"' + key + '" is not a valid option.');
    //     }
    // }
    (this || _global).settings = _extend({}, DEFAULTS, options);
    (this || _global).measurements = {
      container: {
        rect: null,
        style: null,
        padding: null
      }
    };
    (this || _global).container = el;
    (this || _global).tag = {
      values: [],
      elements: []
    };
    (this || _global).list = document.createElement("ul");
    (this || _global).inputLi = document.createElement("li");
    (this || _global).input = document.createElement("input");
    (this || _global).sizer = document.createElement("div");
    (this || _global).pasting = false;
    (this || _global).placeholder = null;
    (this || _global).data = null;

    if ((this || _global).settings.placeholder) {
      (this || _global).placeholder = document.createElement("span");
    }

    if (typeof el === "string") {
      (this || _global).container = document.getElementById(el);
    }

    (this || _global)._id = 0;
    (this || _global)._backspacePressed = false;
    (this || _global)._inputPosition = 0;
    (this || _global)._closeEvents = [];
    (this || _global)._closeButtons = [];

    this._setMeasurements();

    this._setupTextarea();

    this._attachEvents();
  };
  /**
   * Gets all the layout measurements up front
   */


  Taggle.prototype._setMeasurements = function () {
    (this || _global).measurements.container.rect = (this || _global).container.getBoundingClientRect();
    (this || _global).measurements.container.style = window.getComputedStyle((this || _global).container);
    var style = (this || _global).measurements.container.style;
    var lpad = parseInt(style["padding-left"] || style.paddingLeft, 10);
    var rpad = parseInt(style["padding-right"] || style.paddingRight, 10);
    var lborder = parseInt(style["border-left-width"] || style.borderLeftWidth, 10);
    var rborder = parseInt(style["border-right-width"] || style.borderRightWidth, 10);
    (this || _global).measurements.container.padding = lpad + rpad + lborder + rborder;
  };
  /**
   * Setup the div container for tags to be entered
   */


  Taggle.prototype._setupTextarea = function () {
    var fontSize;
    (this || _global).list.className = "taggle_list";
    (this || _global).input.type = "text"; // Make sure no left/right padding messes with the input sizing

    (this || _global).input.style.paddingLeft = 0;
    (this || _global).input.style.paddingRight = 0;
    (this || _global).input.className = "taggle_input";
    (this || _global).input.tabIndex = (this || _global).settings.tabIndex;
    (this || _global).sizer.className = "taggle_sizer";

    if ((this || _global).settings.tags.length) {
      for (var i = 0, len = (this || _global).settings.tags.length; i < len; i++) {
        var taggle = this._createTag((this || _global).settings.tags[i], (this || _global).tag.values.length);

        (this || _global).list.appendChild(taggle);
      }
    }

    if ((this || _global).placeholder) {
      this._hidePlaceholder();

      (this || _global).placeholder.classList.add("taggle_placeholder");

      (this || _global).container.appendChild((this || _global).placeholder);

      _setText((this || _global).placeholder, (this || _global).settings.placeholder);

      if (!(this || _global).settings.tags.length) {
        this._showPlaceholder();
      }
    }

    var formattedInput = (this || _global).settings.inputFormatter((this || _global).input);

    if (formattedInput) {
      (this || _global).input = formattedInput;
    }

    (this || _global).inputLi.appendChild((this || _global).input);

    (this || _global).list.appendChild((this || _global).inputLi);

    (this || _global).container.appendChild((this || _global).list);

    (this || _global).container.appendChild((this || _global).sizer);

    fontSize = window.getComputedStyle((this || _global).input).fontSize;
    (this || _global).sizer.style.fontSize = fontSize;
  };
  /**
   * Attaches neccessary events
   */


  Taggle.prototype._attachEvents = function () {
    var self = this || _global;

    if ((this || _global)._eventsAttached) {
      return false;
    }

    (this || _global)._eventsAttached = true;

    function containerClick() {
      self.input.focus();
    }

    if ((this || _global).settings.focusInputOnContainerClick) {
      (this || _global)._handleContainerClick = containerClick.bind(this || _global);

      _on((this || _global).container, "click", (this || _global)._handleContainerClick);
    }

    (this || _global)._handleFocus = (this || _global)._setFocusStateForContainer.bind(this || _global);
    (this || _global)._handleBlur = (this || _global)._blurEvent.bind(this || _global);
    (this || _global)._handleKeydown = (this || _global)._keydownEvents.bind(this || _global);
    (this || _global)._handleKeyup = (this || _global)._keyupEvents.bind(this || _global);

    _on((this || _global).input, "focus", (this || _global)._handleFocus);

    _on((this || _global).input, "blur", (this || _global)._handleBlur);

    _on((this || _global).input, "keydown", (this || _global)._handleKeydown);

    _on((this || _global).input, "keyup", (this || _global)._handleKeyup);

    return true;
  };

  Taggle.prototype._detachEvents = function () {
    if (!(this || _global)._eventsAttached) {
      return false;
    }

    var self = this || _global;
    (this || _global)._eventsAttached = false;

    _off((this || _global).container, "click", (this || _global)._handleContainerClick);

    _off((this || _global).input, "focus", (this || _global)._handleFocus);

    _off((this || _global).input, "blur", (this || _global)._handleBlur);

    _off((this || _global).input, "keydown", (this || _global)._handleKeydown);

    _off((this || _global).input, "keyup", (this || _global)._handleKeyup);

    (this || _global)._closeButtons.forEach(function (button, i) {
      var eventFn = self._closeEvents[i];

      _off(button, "click", eventFn);
    });

    return true;
  };
  /**
   * Resizes the hidden input where user types to fill in the
   * width of the div
   */


  Taggle.prototype._fixInputWidth = function () {
    this._setMeasurements();

    this._setInputWidth();
  };
  /**
   * Returns whether or not the specified tag text can be added
   * @param  {Event} e event causing the potentially added tag
   * @param  {String} text tag value
   * @return {Boolean}
   */


  Taggle.prototype._canAdd = function (e, text) {
    if (!text) {
      return false;
    }

    var limit = (this || _global).settings.maxTags;

    if (limit !== null && limit <= this.getTagValues().length) {
      return false;
    }

    if ((this || _global).settings.onBeforeTagAdd(e, text) === false) {
      return false;
    }

    if (!(this || _global).settings.allowDuplicates && this._hasDupes(text)) {
      return false;
    }

    var sensitive = (this || _global).settings.preserveCase;
    var allowed = (this || _global).settings.allowedTags;

    if (allowed.length && !this._tagIsInArray(text, allowed, sensitive)) {
      return false;
    }

    var disallowed = (this || _global).settings.disallowedTags;

    if (disallowed.length && this._tagIsInArray(text, disallowed, sensitive)) {
      return false;
    }

    return true;
  };
  /**
   * Returns whether a string is in an array based on case sensitivity
   *
   * @param  {String} text string to search for
   * @param  {Array} arr array of strings to search through
   * @param  {Boolean} caseSensitive
   * @return {Boolean}
   */


  Taggle.prototype._tagIsInArray = function (text, arr, caseSensitive) {
    if (caseSensitive) {
      return arr.indexOf(text) !== -1;
    }

    var lowercased = [].slice.apply(arr).map(function (str) {
      return str.toLowerCase();
    });
    return lowercased.indexOf(text) !== -1;
  };
  /**
   * Appends tag with its corresponding input to the list
   * @param  {Event} e
   * @param  {String} text
   * @param  {Number} index
   */


  Taggle.prototype._add = function (e, text, index) {
    var self = this || _global;
    var values = text || "";
    var delimiter = (this || _global).settings.delimiter || (this || _global).settings.delimeter;

    if (typeof text !== "string") {
      values = (this || _global).input.value;

      if ((this || _global).settings.trimTags) {
        if (values[0] === delimiter) {
          values = values.replace(delimiter, "");
        }

        values = _trim(values);
      }
    }

    values.split(delimiter).map(function (val) {
      if (self.settings.trimTags) {
        val = _trim(val);
      }

      return self._formatTag(val);
    }).forEach(function (val) {
      if (!self._canAdd(e, val)) {
        return;
      }

      var currentTagLength = self.tag.values.length;

      var tagIndex = _clamp(index || currentTagLength, 0, currentTagLength);

      var li = self._createTag(val, tagIndex);

      var lis = self.list.children;
      var lastLi = lis[tagIndex];
      self.list.insertBefore(li, lastLi);
      val = self.tag.values[tagIndex];
      self.settings.onTagAdd(e, val);
      self.input.value = "";

      self._fixInputWidth();

      self._setFocusStateForContainer();
    });
  };
  /**
   * Removes last tag if it has already been probed
   * @param  {Event} e
   */


  Taggle.prototype._checkPrevOrNextTag = function (e) {
    e = e || window.event;

    var taggles = (this || _global).container.querySelectorAll(".taggle");

    var prevTagIndex = _clamp((this || _global)._inputPosition - 1, 0, taggles.length - 1);

    var nextTagIndex = _clamp((this || _global)._inputPosition, 0, taggles.length - 1);

    var index = prevTagIndex;

    if (e.keyCode === DELETE) {
      index = nextTagIndex;
    }

    var targetTaggle = taggles[index];
    var hotClass = "taggle_hot";
    var isDeleteOrBackspace = [BACKSPACE, DELETE].indexOf(e.keyCode) !== -1; // prevent holding backspace from deleting all tags

    if ((this || _global).input.value === "" && isDeleteOrBackspace && !(this || _global)._backspacePressed) {
      if (targetTaggle.classList.contains(hotClass)) {
        (this || _global)._backspacePressed = true;

        this._remove(targetTaggle, e);

        this._fixInputWidth();

        this._setFocusStateForContainer();
      } else {
        targetTaggle.classList.add(hotClass);
      }
    } else if (targetTaggle.classList.contains(hotClass)) {
      targetTaggle.classList.remove(hotClass);
    }
  };
  /**
   * Setter for the hidden input.
   * @param {Number} width
   */


  Taggle.prototype._setInputWidth = function () {
    var width = (this || _global).sizer.getBoundingClientRect().width;

    var max = (this || _global).measurements.container.rect.width - (this || _global).measurements.container.padding;
    var size = parseInt((this || _global).sizer.style.fontSize, 10); // 1.5 just seems to be a good multiplier here

    var newWidth = Math.round(_clamp(width + size * 1.5, 10, max));
    (this || _global).input.style.width = newWidth + "px";
  };
  /**
   * Checks global tags array if provided tag exists
   * @param  {String} text
   * @return {Boolean}
   */


  Taggle.prototype._hasDupes = function (text) {
    var needle = (this || _global).tag.values.indexOf(text);

    var tagglelist = (this || _global).container.querySelector(".taggle_list");

    var dupes;

    if ((this || _global).settings.duplicateTagClass) {
      dupes = tagglelist.querySelectorAll("." + (this || _global).settings.duplicateTagClass);

      for (var i = 0, len = dupes.length; i < len; i++) {
        dupes[i].classList.remove((this || _global).settings.duplicateTagClass);
      }
    } // if found


    if (needle > -1) {
      if ((this || _global).settings.duplicateTagClass) {
        tagglelist.childNodes[needle].classList.add((this || _global).settings.duplicateTagClass);
      }

      return true;
    }

    return false;
  };
  /**
   * Checks whether or not the key pressed is acceptable
   * @param  {Number}  key code
   * @return {Boolean}
   */


  Taggle.prototype._isConfirmKey = function (key) {
    var confirmKey = false;

    if ((this || _global).settings.submitKeys.indexOf(key) > -1) {
      confirmKey = true;
    }

    return confirmKey;
  }; // Event handlers

  /**
   * Handles focus state of div container.
   */


  Taggle.prototype._setFocusStateForContainer = function () {
    this._fixInputWidth();

    if (!(this || _global).container.classList.contains((this || _global).settings.containerFocusClass)) {
      (this || _global).container.classList.add((this || _global).settings.containerFocusClass);
    }

    this._hidePlaceholder();
  };
  /**
   * Runs all the events that need to happen on a blur
   * @param  {Event} e
   */


  Taggle.prototype._blurEvent = function (e) {
    if ((this || _global).container.classList.contains((this || _global).settings.containerFocusClass)) {
      (this || _global).container.classList.remove((this || _global).settings.containerFocusClass);
    }

    if ((this || _global).settings.saveOnBlur) {
      e = e || window.event;

      this._setInputWidth();

      if ((this || _global).input.value !== "") {
        this._confirmValidTagEvent(e);

        return;
      }

      if ((this || _global).tag.values.length) {
        this._checkPrevOrNextTag(e);
      }
    } else if ((this || _global).settings.clearOnBlur) {
      (this || _global).input.value = "";

      this._setInputWidth();
    }

    if (!(this || _global).tag.values.length && !(this || _global).input.value) {
      this._showPlaceholder();
    }
  };
  /**
   * Runs all the events that need to run on keydown
   * @param  {Event} e
   */


  Taggle.prototype._keydownEvents = function (e) {
    e = e || window.event;
    var key = e.keyCode;
    (this || _global).pasting = false;

    this._setInputWidth();

    if (key === 86 && e.metaKey) {
      (this || _global).pasting = true;
    }

    if (this._isConfirmKey(key) && (this || _global).input.value !== "") {
      this._confirmValidTagEvent(e);

      return;
    }

    if ((this || _global).tag.values.length) {
      this._checkPrevOrNextTag(e);
    }
  };
  /**
   * Runs all the events that need to run on keyup
   * @param  {Event} e
   */


  Taggle.prototype._keyupEvents = function (e) {
    e = e || window.event;
    (this || _global)._backspacePressed = false;

    if ([ARROW_LEFT, ARROW_RIGHT].indexOf(e.keyCode) !== -1) {
      this._moveInput(e.keyCode);

      return;
    }

    _setText((this || _global).sizer, (this || _global).input.value); // If we break to a new line because the text is too long
    // and decide to delete everything, we should resize the input
    // so it falls back inline


    if (!(this || _global).input.value) {
      this._setInputWidth();
    }

    if ((this || _global).pasting && (this || _global).input.value !== "") {
      this._add(e);

      (this || _global).pasting = false;
    }
  };

  Taggle.prototype._moveInput = function (direction) {
    var currentIndex = (this || _global)._inputPosition;

    switch (direction) {
      case ARROW_LEFT:
        {
          var leftNewIndex = _clamp((this || _global)._inputPosition - 1, 0, (this || _global).tag.values.length);

          var leftIndexChanged = currentIndex !== leftNewIndex;
          (this || _global)._inputPosition = leftNewIndex;

          if (leftIndexChanged) {
            (this || _global).list.insertBefore((this || _global).inputLi, (this || _global).list.childNodes[leftNewIndex] || null);

            (this || _global).input.focus();
          }

          break;
        }

      case ARROW_RIGHT:
        {
          var rightNewIndex = _clamp((this || _global)._inputPosition + 1, 0, (this || _global).tag.values.length);

          var rightIndexChanged = currentIndex !== rightNewIndex;
          (this || _global)._inputPosition = rightNewIndex;

          if (rightIndexChanged) {
            (this || _global).list.insertBefore((this || _global).inputLi, (this || _global).list.childNodes[rightNewIndex + 1] || null);

            (this || _global).input.focus();
          }

          break;
        }

      default:
        break;
    }
  };
  /**
   * Confirms the inputted value to be converted to a tag
   * @param  {Event} e
   */


  Taggle.prototype._confirmValidTagEvent = function (e) {
    e = e || window.event; // prevents from jumping out of textarea

    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    this._add(e, null, (this || _global)._inputPosition);
  };

  Taggle.prototype._createTag = function (text, index) {
    var li = document.createElement("li");
    var close = document.createElement("button");
    var hidden = document.createElement("input");
    var span = document.createElement("span");
    text = this._formatTag(text);

    _setText(close, "\xD7");

    close.className = "close"; // IE8 does not allow you to modify the `type` property directly.

    close.setAttribute("type", "button");

    var eventFn = (this || _global)._remove.bind(this || _global, close);

    _on(close, "click", eventFn);

    _setText(span, text);

    span.className = "taggle_text";
    li.className = "taggle " + (this || _global).settings.additionalTagClasses;
    hidden.type = "hidden";
    hidden.value = text;
    hidden.name = (this || _global).settings.hiddenInputName;
    li.appendChild(span);
    li.appendChild(close);
    li.appendChild(hidden);

    var formatted = (this || _global).settings.tagFormatter(li);

    if (typeof formatted !== "undefined") {
      li = formatted;
    }

    if (!(li instanceof HTMLElement) || !(li.localName === "li" || li.tagName === "LI")) {
      throw new Error("tagFormatter must return an li element");
    }

    if ((this || _global).settings.attachTagId) {
      (this || _global)._id += 1;
      text = {
        text: text,
        id: (this || _global)._id
      };
    }

    (this || _global).tag.values.splice(index, 0, text);

    (this || _global).tag.elements.splice(index, 0, li);

    (this || _global)._closeEvents.splice(index, 0, eventFn);

    (this || _global)._closeButtons.splice(index, 0, close);

    (this || _global)._inputPosition = _clamp((this || _global)._inputPosition + 1, 0, (this || _global).tag.values.length);
    return li;
  };

  Taggle.prototype._showPlaceholder = function () {
    if ((this || _global).placeholder) {
      (this || _global).placeholder.style.opacity = 1;

      (this || _global).placeholder.setAttribute("aria-hidden", "false");
    }
  };

  Taggle.prototype._hidePlaceholder = function () {
    if ((this || _global).placeholder) {
      (this || _global).placeholder.style.opacity = 0;

      (this || _global).placeholder.setAttribute("aria-hidden", "true");
    }
  };
  /**
   * Removes tag from the tags collection
   * @param  {li} li List item to remove
   * @param  {Event} e
   */


  Taggle.prototype._remove = function (li, e) {
    var self = this || _global;
    var text;
    var elem;
    var index;

    if (li.tagName.toLowerCase() !== "li") {
      li = li.parentNode;
    }

    elem = li.tagName.toLowerCase() === "a" ? li.parentNode : li;
    index = (this || _global).tag.elements.indexOf(elem);
    text = (this || _global).tag.values[index];

    function done(error) {
      if (error) {
        return;
      }

      var eventFn = self._closeEvents[index];
      var button = self._closeButtons[index];

      _off(button, "click", eventFn);

      li.parentNode.removeChild(li); // Going to assume the indicies match for now

      self.tag.elements.splice(index, 1);
      self.tag.values.splice(index, 1);

      self._closeEvents.splice(index, 1);

      self._closeButtons.splice(index, 1);

      self.settings.onTagRemove(e, text);

      if (index < self._inputPosition) {
        self._inputPosition = _clamp(self._inputPosition - 1, 0, self.tag.values.length);
      }

      self._setFocusStateForContainer();
    }

    var ret = (this || _global).settings.onBeforeTagRemove(e, text, done);

    if (!ret) {
      return;
    }

    done();
  };
  /**
   * Format the text for a tag
   * @param {String} text Tag text
   * @return {String}
   */


  Taggle.prototype._formatTag = function (text) {
    return (this || _global).settings.preserveCase ? text : text.toLowerCase();
  };

  Taggle.prototype._isIndexInRange = function (index) {
    return index >= 0 && index <= (this || _global).tag.values.length - 1;
  };

  Taggle.prototype.getTags = function () {
    return {
      elements: this.getTagElements(),
      values: this.getTagValues()
    };
  }; // @todo
  // @deprecated use getTags().elements


  Taggle.prototype.getTagElements = function () {
    return [].slice.apply((this || _global).tag.elements);
  }; // @todo
  // @deprecated use getTags().values


  Taggle.prototype.getTagValues = function () {
    return [].slice.apply((this || _global).tag.values);
  };

  Taggle.prototype.getInput = function () {
    return (this || _global).input;
  };

  Taggle.prototype.getContainer = function () {
    return (this || _global).container;
  };

  Taggle.prototype.add = function (text, index) {
    var isArr = _isArray(text);

    if (isArr) {
      var startingIndex = index;

      for (var i = 0, len = text.length; i < len; i++) {
        if (typeof text[i] === "string") {
          this._add(null, text[i], startingIndex);

          if (!isNaN(startingIndex)) {
            startingIndex += 1;
          }
        }
      }
    } else {
      this._add(null, text, index);
    }

    return this || _global;
  };

  Taggle.prototype.edit = function (text, index) {
    if (typeof text !== "string") {
      throw new Error("First edit argument must be of type string");
    }

    if (typeof index !== "number") {
      throw new Error("Second edit argument must be a number");
    }

    if (!this._isIndexInRange(index)) {
      throw new Error("Edit index should be between 0 and " + (this || _global).tag.values.length - 1);
    }

    var textValue = (this || _global).tag.values[index];

    if (typeof textValue === "string") {
      (this || _global).tag.values[index] = text;
    } else {
      (this || _global).tag.values[index].text = text;
    }

    _setText((this || _global).tag.elements[index], text);

    return this || _global;
  };

  Taggle.prototype.move = function (currentIndex, destinationIndex) {
    if (typeof currentIndex !== "number" || typeof destinationIndex !== "number") {
      throw new Error("Both arguments must be numbers");
    }

    if (!this._isIndexInRange(currentIndex)) {
      throw new Error("First index should be between 0 and " + (this || _global).tag.values.length - 1);
    }

    if (!this._isIndexInRange(destinationIndex)) {
      throw new Error("Second index should be between 0 and " + (this || _global).tag.values.length - 1);
    }

    if (currentIndex === destinationIndex) {
      return this || _global;
    }

    var value = (this || _global).tag.values[currentIndex];
    var element = (this || _global).tag.elements[currentIndex];
    var lastElement = (this || _global).tag.elements[destinationIndex];
    var eventFn = (this || _global)._closeEvents[currentIndex];
    var closeButton = (this || _global)._closeButtons[currentIndex];

    (this || _global).tag.values.splice(currentIndex, 1);

    (this || _global).tag.elements.splice(currentIndex, 1);

    (this || _global)._closeEvents.splice(currentIndex, 1);

    (this || _global)._closeButtons.splice(currentIndex, 1);

    (this || _global).tag.values.splice(destinationIndex, 0, value);

    (this || _global).tag.elements.splice(destinationIndex, 0, element);

    (this || _global)._closeEvents.splice(currentIndex, 0, eventFn);

    (this || _global)._closeButtons.splice(currentIndex, 0, closeButton);

    (this || _global).list.insertBefore(element, lastElement.nextSibling);

    return this || _global;
  };

  Taggle.prototype.remove = function (text, all) {
    var len = (this || _global).tag.values.length - 1;
    var found = false;

    while (len > -1) {
      var tagText = (this || _global).tag.values[len];

      if ((this || _global).settings.attachTagId) {
        tagText = tagText.text;
      }

      if (tagText === text) {
        found = true;

        this._remove((this || _global).tag.elements[len]);
      }

      if (found && !all) {
        break;
      }

      len--;
    }

    return this || _global;
  };

  Taggle.prototype.removeAll = function () {
    for (var i = (this || _global).tag.values.length - 1; i >= 0; i--) {
      this._remove((this || _global).tag.elements[i]);
    }

    this._showPlaceholder();

    return this || _global;
  };

  Taggle.prototype.setOptions = function (options) {
    (this || _global).settings = _extend({}, (this || _global).settings, options || {});
    return this || _global;
  };

  Taggle.prototype.enable = function () {
    var buttons = [].slice.call((this || _global).container.querySelectorAll("button"));
    var inputs = [].slice.call((this || _global).container.querySelectorAll("input"));
    buttons.concat(inputs).forEach(function (el) {
      el.removeAttribute("disabled");
    });
    return this || _global;
  };

  Taggle.prototype.disable = function () {
    var buttons = [].slice.call((this || _global).container.querySelectorAll("button"));
    var inputs = [].slice.call((this || _global).container.querySelectorAll("input"));
    buttons.concat(inputs).forEach(function (el) {
      el.setAttribute("disabled", "");
    });
    return this || _global;
  };

  Taggle.prototype.setData = function (data) {
    (this || _global).data = data;
    return this || _global;
  };

  Taggle.prototype.getData = function () {
    return (this || _global).data;
  };

  Taggle.prototype.attachEvents = function () {
    var self = this || _global;

    var attached = this._attachEvents();

    if (attached) {
      (this || _global)._closeButtons.forEach(function (button, i) {
        var eventFn = self._closeEvents[i];

        _on(button, "click", eventFn);
      });
    }

    return this || _global;
  };

  Taggle.prototype.removeEvents = function () {
    this._detachEvents();

    return this || _global;
  };

  return Taggle;
});

export default exports;